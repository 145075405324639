#mobile-menu {
  visibility: hidden;
  transform: translateX(-300px);
  float: none !important;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 290px !important;
  background-color: #84a40b;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: transform .2s;
  -moz-transition: transform .2s;
  -ms-transition: transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  -webkit-transform: translateX(-300px);
  -ms-transform: translateX(-300px);
  z-index: 9999999;
  border-right: 1px solid #000;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;

    a {
      display: block;
      padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px dashed #000;

      &.active,
      &:focus,
      &:hover {
        color: #fff !important;
      }
    }
  }
}

.o-overlay {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0;
  -webkit-transition: opacity .25s;
  -o-transition: opacity .25s;
  transition: opacity .25s;
}

@media only screen and (max-width: 991px) {
  .mobile-nav-active {

    position: fixed;

    #mobile-menu {
      visibility: visible;
      -webkit-overflow-scrolling: touch;
      -webkit-transition: transform .2s;
      -moz-transition: transform .2s;
      -ms-transition: transform .2s;
      -o-transition: transform .2s;
      transition: transform .2s;
      transform: translate(0,0) !important;
    }

    .o-overlay {
      height: 100%;
      position: absolute;
      opacity: .6;
    }

  }



}